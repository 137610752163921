<template>
  <div class="annListWrap eventTab">
    <div class="group shadowBox_center" v-for="(item,index) in dataInfo" :key="index">
      <div class="layoutLeft">
        <span class="title textline1">旅游资讯</span>
        <img class="gap" :src="require('../../assets/image/eventInfo/gap.jpg')" alt />
        <div class="contentWrap">
          <span class="title textline2">{{item.title}}</span>
          <span class="subtitle textline4">{{item.subtitle}}</span>
          <div class="footerWrap">
            <span class="date">{{item.create_time.substring(0,10)}}</span>
            <div class="btn" @click="toDetail(item.id)">立即前往</div>
          </div>
        </div>
      </div>
      <div class="layoutRight">
        <img :src="baseUrl + item.main_image_id" alt />
      </div>
    </div>
    <div class="pagWrap">
      <Pagination v-show="total>5" :total="total" :perPageSize="5" @change="changePagination"></Pagination>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Pagination from "@/components/pagination";
import axios from "axios";
import config from "@/utils/config";

import { checkIsNumber } from "@/utils/util.js";

export default {
  props: {
    title: {
      type: String,
      default: "旅游资讯"
    }
  },
  components: {
    Pagination
  },
  data() {
    return {
      total: 0, // 必传传递参数total
      list: new Array(5),
      dataInfo: new Array()
    };
  },
  created() {
    let value = this.$route.query.title;
  },
  mounted() {
    this.getData(config.eventTravelUrl);
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  methods: {
    changePagination(cur) {
      this.current = cur;
      let url =
        config.eventAnnouceUrl.split("?")[0] +
        "?class_type=旅游资讯&page=" +
        cur;
      this.getData(url);
    },
    setPageBarParams(count) {
      if (count != null) {
        if (count == "") {
          this.total = 0;
        } else {
          let flag = checkIsNumber(count);
          if (flag) {
            this.total = count;
          }
        }
      }
      // console.log("item count:", count);
    },
    async getData(url) {
      try {
        let res = await axios.get(url);
        if (res.data.code == "0" && res.data.data instanceof Array) {
          this.setPageBarParams(res.data.count);
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            this.dataInfo = tempData;
            // console.log("res.data.data:", this.dataInfo);
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }
    },
    toDetail(id) {
      this.$router.push({ path: "/eventInfoDetail", query: { id: id } });
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
@import "src/assets/styles/announcement/announcement.scss";
</style>
